import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/OKALFh44nDw">
    <SEO title="Table of Nations - Counter Culture" />
  </Layout>
)

export default SermonPost
